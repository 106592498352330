import React from 'react';
import * as L from 'components/@UI/@Layout';
import { Country, Lang } from "./types"
import * as assets from './assets';
import styled from "styled-components"

const Container = styled(L.Flex)`
  width: 100%;
  position: relative;
`;
const SelectedLang = styled(L.Flex)`
  flex: 1 0 0;
  background: #FFFFFF;
  border: 1px solid #BFC4D4;
  box-sizing: border-box;
  padding: 0 20px 0 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const LanguageLabel = styled(L.Flex)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #3D4156;
  margin-left: 8px;
`;
const Wrapper = styled(L.Flex)`
  align-items: center;
`;
const ArrowIconWrapper = styled(L.Flex)``;
const LangIcon = styled('img')`
  margin: 0;
  width: 16px;
  height: 11px;
`;
const OptionsWrapper = styled(L.Flex)`
  width: 100%;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
`;
const OptionContainer = styled(L.Flex)`
  flex: 1 0 0;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  border: 1px solid #BFC4D4;
  border-top: none;
  box-sizing: border-box;
  padding: 0 20px 0 16px;
  cursor: pointer;
`;

const OPTIONS: Lang[] = ['ru', 'en'];

export const getCountry = (lang: Lang): Country => {
  switch (lang) {
    case 'ru': {
      return 'Ru';
    }
    case 'en': {
      return 'Us';
    }
  }
}

export const getLang = (country: Country): Lang => {
  switch (country) {
    case 'Ru': {
      return 'ru';
    }
    case 'Us': {
      return 'en';
    }
  }
}

export interface LanguageSelectorProps {
  selectedLang: Country;
  onLangSelect(lang: Country): void;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLang = 'Us', onLangSelect }) => {
  const normalizedCountry = selectedLang.slice(0, 1).toLocaleUpperCase() + selectedLang.slice(1, selectedLang.length).toLowerCase();
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const handleToggle = (): void => setIsOpened(isOpened => !isOpened);
  const handleLangSelect = (lang: Lang) => () => {
    onLangSelect(getCountry(lang));
  };
  return (
    <Container>
      <SelectedLang onClick={handleToggle}>
        <Wrapper>
          <LangIcon src={assets[normalizedCountry]} />
          <LanguageLabel>{getLang(selectedLang)}</LanguageLabel>
        </Wrapper>
        <ArrowIconWrapper>
          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 6L10 0H0L5 6Z" fill="#6B7297"/>
          </svg>
        </ArrowIconWrapper>
      </SelectedLang>
      {isOpened && (
        <OptionsWrapper>
          {OPTIONS.filter(option => option !== getLang(selectedLang)).map(option => (
            <OptionContainer key={option} onClick={handleLangSelect(option)}>
              <LangIcon src={assets[getCountry(option)]} />
              <LanguageLabel>{option}</LanguageLabel>
            </OptionContainer>
          ))}
        </OptionsWrapper>
      )}
    </Container>
  );
}

import * as React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as intl from 'gatsby-plugin-intl';
import ReactGA from 'react-ga';
import * as Helmet from 'react-helmet';
import * as UI from 'components/@UI';
import * as L from 'components/@UI/@Layout';
import * as $UniqueSellingProposition from 'components/@Elements/@UniqueSellingProposition';
import Pricing from '../components/@Elements/@Pricing/Pricing';
import { TextBlock } from 'components/@Elements/@TextBlock';
import { Tabs } from 'components/@Elements/@Tabs';
import { FullControlCards } from 'components/@Elements/@FullControlCards';
import Layout from '../components/Layout';
import theme from 'configs/styled/theme';
import { API_KEY } from 'configs/amplitude';
import { loadIntercom } from 'configs/intercom';
import { GA_TRACKING_ID } from 'configs/ga';
import { AmplitudeClient } from 'amplitude-js';
import { Country } from "components/@Elements/@Header/LanguageSelector/types";
import { getCountry, getLang } from "components/@Elements/@Header/LanguageSelector/LanguageSelector";
import { useStaticQuery, graphql } from "gatsby";

const APP_URL = process.env.GATSBY_APP_URL || 'https://apptest.asoone.net';
const LOGIN_URL = `${APP_URL}/login`;
const REGISTER_URL = `${APP_URL}/register`;

const LANGS_RU_ADD = ['ru-analytic', 'ru-semantic', 'ru-revenue', 'ru-sale'];
const LANGS_RU = ['ru', ...LANGS_RU_ADD];
const LANGS_EN = ['en'];

const getRandomItem = (items: string[]): string => items[Math.floor(Math.random() * items.length)];

const TextBlockContainer = styled(L.Box)`
  margin-top: 64px;
  @media screen and (min-width: 1024px) {
    margin-top: 43px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 56px;
  }
`;

const TabsContainer = styled(L.Box)`
  margin-top: 66px;
  @media screen and (min-width: 1024px) {
    margin-top: 48px;
  }
`;

const Fonts = createGlobalStyle`
  * {
    font-family: 'Raleway', sans-serif;
  }
`;

const ButtonWrapper = styled(L.Flex)`
  margin-top: 8px;
  justify-content: center;
`;

const RegisterButton = styled(UI.Button)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;

  padding: 0 23px;
  height: 52px;
  background: #E36A9D;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

const RegisterLink = styled(UI.Link)`
  text-decoration: none;
`;

const FullControlCardsWrapper = styled(L.Box)`
  margin-top: 64px;
  @media screen and (min-width: 1024px) {
    margin-top: 88px;
  }
`;

const ClientsContainer = styled(L.Box)`
  margin-top: 59px;
`;

const ArticlesContainer = styled(L.Box)`
  margin-top: 40px;
`;

const SmallUniqueSellingPropositionContainer = styled(L.Box)`
  margin-top: 88px;
`;

const getTestFromLS = () => typeof window !== 'undefined' && window.localStorage.getItem('test');

const IndexPage = (props) => {
  const [client, setClient] = React.useState<AmplitudeClient | null>(null);

  const urlParams = new URLSearchParams(props.location.search.substring(1));
  const testParam = urlParams.get('test');
  const testFromLS = getTestFromLS();
  const isTest = (testParam === 'test') || (testFromLS === 'test');
  const isRedirected = urlParams.get('redirected') === 'redirected';

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "icon-aso.png" }) {
        childImageSharp {
          fixed(width: 400, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);


  const handleTabClick = (tabTitle: string): void => {
    if (client) {
      client.logEvent('Landing FeatureTabTap', { tabTitle });
    }
  };

  const handleLoginClick = (index: number) => (): void => {
    if (client) {
      client.logEvent('Landing LoginTap', { index });
    }
  };

  const handleRegisterClick = (index: number) => (): void => {
    if (client) {
      client.logEvent('Landing RegisterTap', { index });
    }
  };

  const lang = props.pathContext.intl.language;

  const registerUrlWithLang = `${REGISTER_URL}?lang=${lang}&test=${isTest ? 'test' : ''}&redirected=${isRedirected ? lang : ''}`;
  const loginUrlWithLang = `${LOGIN_URL}?lang=${lang}&test=${isTest ? 'test' : ''}&redirected=${isRedirected ? lang : ''}`;

  const amplitudeInit = () => {
    const amplitude = require('amplitude-js');
    amplitude.getInstance().init(API_KEY, undefined, undefined, (client) => {
      setClient(client);
      const shouldEmmitOpenEvent = (
        (lang === 'en')
        || ((lang === 'ru') && isRedirected)
        || (LANGS_RU_ADD.includes(lang))
      );
      if (shouldEmmitOpenEvent) {
        client.logEvent('Landing Open', { langId: lang });
      }
    });
  }

  React.useEffect(() => {
    if (!isTest) {
      // amplitude
      if (process.browser) {
        amplitudeInit();
      }
      //Intercom
      loadIntercom(lang.slice(0, 2));
      // Init Google Analyttics
      ReactGA.initialize(GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    // Test Mode
    if (isTest) {
      window.localStorage.setItem('test', 'test');
    }
  }, []);

  const selectedLang = getCountry(props.intl.locale.slice(0, 2));
  const handleLangSelect = (lang: Country): void => props.navigate(`/${getLang(lang)}`);

  const bookUrl = 'https://calendly.com/asoone/60min';

  const renderPage = () => {
    // if (lang === 'ru' && !isRedirected) {
    //   const langIdFromLS = (typeof localStorage != "undefined") ? localStorage.getItem('langId') : null;
    //   const newLang = langIdFromLS || getRandomItem(LANGS_RU);
    //   if (typeof localStorage != "undefined") {
    //     localStorage.setItem('langId', newLang);
    //   }
    //   return (
    //     <Redirect
    //       noThrow={true}
    //       from='/ru'
    //       to={`/${newLang}?redirected=redirected`}
    //     />
    //   );
    // }
    return (
      <ThemeProvider theme={theme}>
        <Layout
          language={selectedLang}
          loginUrl={loginUrlWithLang}
          registerUrl={registerUrlWithLang}
          onLoginClick={handleLoginClick(0)}
          onRegisterClick={handleRegisterClick(0)}
          onLangSelect={handleLangSelect}
        >
          <$UniqueSellingProposition.UniqueSellingProposition
            registerUrl={registerUrlWithLang}
            loginUrl={loginUrlWithLang}
            onLoginClick={handleLoginClick(1)}
            onRegisterClick={handleRegisterClick(1)}
          />

          <TextBlockContainer>
            <TextBlock>
              <intl.FormattedMessage id="index.textBlock1" />
            </TextBlock>
          </TextBlockContainer>
          <TabsContainer>
            <Tabs
              registerUrl={registerUrlWithLang}
              onTabClick={handleTabClick}
            />
          </TabsContainer>

          <TextBlockContainer>
            <TextBlock>
              <intl.FormattedMessage id="index.textBlock2" />
            </TextBlock>
          </TextBlockContainer>
          <ButtonWrapper>
            <RegisterLink href={registerUrlWithLang} onClick={handleRegisterClick(2)}>
              <RegisterButton color="pink">
                <intl.FormattedMessage id="header.registrationBtn" />
              </RegisterButton>
            </RegisterLink>
          </ButtonWrapper>

          <FullControlCardsWrapper>
            <FullControlCards />
          </FullControlCardsWrapper>

          {/*<TextBlockContainer>*/}
          {/*  <TextBlock>*/}
          {/*    <intl.FormattedMessage id="index.textBlock3" />*/}
          {/*  </TextBlock>*/}
          {/*</TextBlockContainer>*/}
          {/*<L.Flex pt={'48px'}>*/}
          {/*  <$Reviews.Reviews />*/}
          {/*</L.Flex>*/}

          {/* <TextBlockContainer>
            <TextBlock>
              <intl.FormattedMessage id="index.textBlock4" />
            </TextBlock>
          </TextBlockContainer>
          <ClientsContainer>
            <$Clients.Clients />
          </ClientsContainer> */}

          <TextBlockContainer>
            <TextBlock>
              <intl.FormattedMessage id="header.priceLink" />
            </TextBlock>
            <Pricing registerUrl={registerUrlWithLang} />
          </TextBlockContainer>
          {/* <ArticlesContainer>
              <$Articles.Articles />
          </ArticlesContainer>

          <SmallUniqueSellingPropositionContainer>
            <$UniqueSellingProposition.SmallUniqueSellingProposition
              registerUrl={registerUrlWithLang}
              onRegisterClick={handleRegisterClick(3)}
            />
          </SmallUniqueSellingPropositionContainer> */}
        </Layout>
      </ThemeProvider>
    );
  };

  return (
    <>
      <intl.FormattedMessage id="page.title">
        {(title: string) => (
          <Helmet.Helmet
            title={title}
            script={[{
              type: 'text/javascript',
              innerHTML: !isTest ? `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          ym(65005618, "init", {
               clickmap:true,
               trackLinks:true,
               accurateTrackBounce:true,
               webvisor:true
          });
          ` : '',
            },
              // {
              //   type: 'text/javascript',
              //   src: `https://assets.calendly.com/assets/external/widget.js`,
              // }
            {
              type: 'text/javascript',
              innerHTML: `window.SC_DISABLE_SPEEDY = true;`,
            }]}
            noscript={[{
              innerHTML: !isTest ? `
          <div><img src="https://mc.yandex.ru/watch/65005618" style="position:absolute; left:-9999px;" alt="" /></div>
          ` : '',
            }]}
          >
            <link href="https://fonts.googleapis.com/css?family=Raleway:400,400i,500,500i,600,600i,700,700i&display=swap" rel="stylesheet" />
            {/*<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />*/}
            <style>
              {'html {overflow-y: inherit;}'}
            </style>
          </Helmet.Helmet>
        )}
      </intl.FormattedMessage>
      <Fonts />
      {renderPage()}
    </>
  );
}

export default injectIntl(IndexPage);

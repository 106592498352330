// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled                          from 'styled-components';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

const colors = helpers.theme.colors;

const Container = styled(L.Box)`
  max-width: 828px;
  margin: 0 auto;
  padding: 0 16px;
  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`;

const PointsWrapper = styled(L.Flex)`
  justify-content: center;
`;

const Point = styled(L.Flex)`
  width: 8px;
  height: 8px;
  background: #4442A8;
  border-radius: 50%;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
`;

const TextContainer = styled(L.Flex)`
  justify-content: center;
  margin-top: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #3D4156;
  @media screen and (min-width: 1024px) {
    font-size: 25px;
    line-height: 40px;
  }
  @media screen and (min-width: 1440px) {
    
  }
`;

export interface TextBlockProps {}

export const TextBlock: React.FC<TextBlockProps> = ({ children }) => (
  <Container>
    <PointsWrapper>
      <Point />
      <Point />
      <Point />
    </PointsWrapper>
    <TextContainer>
      {children}
    </TextContainer>
  </Container>
);

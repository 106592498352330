// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';

import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';

import styled                          from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

const colors = helpers.theme.colors;

export const Container = styled(L.Flex)`
  width: 100%;
`;

export const TextBlock = styled(L.Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const Text = styled(UI.Regular15)`
  font-style: italic;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  color: #535875;
  width: 332px;
`;

export const ButtonWrapper = styled(L.Flex)`
  margin-top: 48px;
  justify-content: flex-start;
`;

export const ImageBlock = styled(L.Flex)`
  box-shadow: 0px 6px 16px rgba(125, 140, 153, 0.4);
  width: 100%;
  height: max-content;
`;

export const TryButton = styled(UI.Button)`
  box-shadow: 0px 0px 0px 1px inset #E3E6ED;
  &:hover {
    background: #EFF1F5;
  }
  &:active {
    background: #E3E6ED;
  }
`;

export const ButnLink = styled(UI.Link)`
  text-decoration: none;
`;

export const ButtonText = styled(UI.Regular15)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #5552ED;
  text-decoration: none;

  margin-right: 8px;
`;

// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled                          from 'styled-components';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

import { Card } from './Card';

import { Svg1, Svg2, Svg3, Svg4 } from './svg/svg';

const colors = helpers.theme.colors;

const Container = styled(L.Flex)`
  margin: 0 auto;
  justify-content: center;
`;

const Wrapper = styled(L.Flex)`
  max-width: 1112px;
  margin: 0 auto;
  flex-direction: column;
`;

const Row = styled(L.Flex)`
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

interface CardData {
  image: React.ReactElement;
  title: string;
  text: string;
}

const CARDS_DATA: CardData[] = [
  {
    image: Svg1,
    title: 'fullControlCards.card1.title',
    text: 'fullControlCards.card1.text',
  },
  {
    image: Svg2,
    title: 'fullControlCards.card2.title',
    text: 'fullControlCards.card2.text',
  },
  {
    image: Svg3,
    title: 'fullControlCards.card3.title',
    text: 'fullControlCards.card3.text',
  },
  {
    image: Svg4,
    title: 'fullControlCards.card4.title',
    text: 'fullControlCards.card4.text',
  },
];

export interface FullControlCardsProps {}

const MAX_ELEMENTS_IN_ROW = 2;

const arrToMatrix = <T extends unknown>(arr: T[], maxElementsInRow: number): T[][] => (
  arr.reduce((prev, curr) => {
    if (prev.length === 0) {
      return [[curr]];
    }
    const lastEl: T[] = prev[prev.length - 1];
    if (lastEl.length === maxElementsInRow) {
      return [...prev, [curr]];
    }
    const newLastEl = [...lastEl, curr];
    return [
      ...(prev.slice(0, prev.length - 1)),
      newLastEl,
    ];
  }, [] as T[][])
);

export const FullControlCards: React.FC<FullControlCardsProps> = () => (
  <Container>
    <Wrapper justifyContent="center" px="0" flexWrap="wrap">
      {arrToMatrix(CARDS_DATA, MAX_ELEMENTS_IN_ROW)
        .map((chunk, index) => (
          <Row key={index}>
            {chunk.map(data => <Card key={data.title} data={data} />)}
          </Row>
        ))}
    </Wrapper>
  </Container>
);

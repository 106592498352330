import React from "react";
import styled from "styled-components";
import * as UI from 'components/@UI';

type SubscriptionProps = {
  registerUrl: string
}

const Container = styled("div")`
  padding: 24px;
  background: #fff;
  margin: 0 auto;
  max-width: 1064px;
  margin-bottom: 50px;
  overflow: auto;
`;

const Rows = styled("div")`
  padding-top: 148px;
  position: relative;
  overflow: auto;
  height: 456px;
  width: 790px;
  margin: 0 auto;
`;

const SingleRow = styled("div")`
  color: #6B7297;
  font-size: 14px;
  line-height: 19px;
  padding: 9px 0;
`;

const SingleRowBorder = styled("div")<{ transparentBorder?: boolean, showCurrentTrialPlan?: boolean }>`
  height: 1px;
  width: 790px;
  background: ${({ transparentBorder }) => (transparentBorder ? "transparent" : "linear-gradient(to right, #E5E7EF, #E5E7EF, #E5E7EF, transparent)")};
  position: relative;
  top: 4px;
`;

const SinglePlan = styled("div")`
  width: 186px;
  height: 454px;
  background: rgba(255, 255, 255, .5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  position: absolute;
  border: 1px solid #ABAEEB;
  box-shadow: 0 4px 8px rgba(130, 139, 184, 0.16);
`;

const PlanName = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 11px;
  text-transform: uppercase;
  color: #4442A8;
`;

const PlanPrice = styled("div")`
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 7px;
`;

const PlanPrice2 = styled("span")`
  font-size: 16px;
  line-height: 22px;
  color: #6B7297;
`;

const PlanFeature = styled(SingleRow)`
  color: #333;
  border-bottom: 1px solid transparent;
`;

const RegisterLink = styled(UI.Link)`
  text-decoration: none;
  height: 53px;
  display: block;
`;

const rows = ['Apps', 'Competitors', 'Keywords', 'Review replies', 'Metadata uploads', 'Autosuggest', 'API Access', 'Priority support'];

type SubscriptionPlan = {
  name: string,
  code: string,
  btnText: string,
  price: number,
  apps: number,
  competitors: number,
  keywords: number,
  reviewReplies: number,
  metadataUploads: string,
  autosuggest: string,
}

const plans: SubscriptionPlan[] = [
  {
    name: 'Free plan',
    code: 'FreePlan',
    btnText: 'register free',
    price: 0,
    apps: 2,
    competitors: 10,
    keywords: 1000,
    reviewReplies: 100,
    metadataUploads: '3',
    autosuggest: 'Limited',
  },
  {
    name: 'Premium',
    code: 'PaidPlan',
    btnText: 'subscribe',
    price: 99,
    apps: 10,
    competitors: 100,
    keywords: 10000,
    reviewReplies: 500,
    metadataUploads: 'Unlimited',
    autosuggest: 'Unlimited',
  },
];

const SubscriptionTable: React.FC<SubscriptionProps> = ({ registerUrl }) => {
  const getIndent = (index: number) => {
    if (index === 0) {
      return '160px';
    }
    if (index === 1) {
      return '360px'
    }
    if (index === 2) {
      return '560px';
    }

    return '160px';
  }

  return (
    <>
      <Container id="price">
        <Rows>
          {rows.map((row) => {
            return (
              <SingleRow key={row}>
                {row}
                <SingleRowBorder transparentBorder={row === 'Priority support'} showCurrentTrialPlan={false} />
              </SingleRow>
            )
          })}

          {plans.map((plan, index) => {
            return (
              <SinglePlan
                key={plan.name}
                style={{
                  top: '0',
                  left: getIndent(index),
                }}
              >
                <PlanName>
                  {plan.name}
                </PlanName>
                <PlanPrice>
                  <span style={{ fontWeight: 500, fontSize: '32px' }}>${plan.price}</span> <PlanPrice2>/ month</PlanPrice2>
                </PlanPrice>
                <RegisterLink href={registerUrl}>
                  <UI.Button color={'primary'} size={'lg'}>
                    {plan.btnText}
                  </UI.Button>
                </RegisterLink>
                <PlanFeature>{plan.apps}</PlanFeature>
                <PlanFeature>{plan.competitors}</PlanFeature>
                <PlanFeature>{plan.keywords}</PlanFeature>
                <PlanFeature>{plan.reviewReplies}</PlanFeature>
                <PlanFeature>{plan.metadataUploads}</PlanFeature>
                <PlanFeature>{plan.autosuggest}</PlanFeature>
                <PlanFeature>-</PlanFeature>
                <PlanFeature>-</PlanFeature>
              </SinglePlan>
            )
          })}
          <SinglePlan
            style={{
              top: '0',
              left: getIndent(2),
            }}
          >
            <PlanName>
              Business
            </PlanName>
            <PlanPrice>
              <PlanPrice2>For personal offer</PlanPrice2>
            </PlanPrice>
            <RegisterLink onClick={() => {
              {/* @ts-ignore */}
              if (window.Intercom) {
                {/* @ts-ignore */}
                window.Intercom("show");
              }
            }}>
              <UI.Button color={'primary'} size={'lg'}>
                Contact us
              </UI.Button>
            </RegisterLink>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>Unlimited</PlanFeature>
            <PlanFeature>+</PlanFeature>
            <PlanFeature>+</PlanFeature>
          </SinglePlan>
        </Rows>
      </Container>
    </>
  )
}

export default SubscriptionTable;

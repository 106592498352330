// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled                          from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

const colors = helpers.theme.colors;

const Container = styled(L.Flex)<{isActive: boolean}>`
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ isActive }) => isActive ? '#4442A8' : 'transparent'};
    z-index: 1;
  }
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
`;

const TitleWrapper = styled(L.Flex)`
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
`;

const Title = styled(L.Flex)<{isActive: boolean}>`
  color: ${({ isActive }) => isActive ? '#332780' : '#535875'};
  margin-left: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export interface TabProps {
  isActive?: boolean;
  icon: React.ElementType;
  title: string;
  onClick(): void;
}

export const Tab: React.FC<TabProps> = ({ icon, title, onClick, isActive = false }) => {
  const Icon = icon;
  return (
    <Container onClick={onClick} isActive={isActive}>
      <TitleWrapper>
        <Icon
          width="21px"
          height="21px"
          color={isActive ? colors.palette.bluepanLight : colors.palette.darkGray}
        />
        <Title isActive={isActive}>
          <intl.FormattedMessage id={title} />
        </Title>
      </TitleWrapper>
    </Container>
  );
}

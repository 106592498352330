// Dependencies
import * as React                                from 'react';
import * as intl from 'gatsby-plugin-intl';
import * as helpers                              from 'helpers/index';
import * as Svg                                  from 'components/@UI/@Svg';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  Container,
  TextBlock,
  Text,
  ButtonWrapper,
  ImageBlock,
  TryButton,
  ButnLink,
  ButtonText,
} from '../TabContent';

const colors = helpers.theme.colors;

export interface TimelineTabProps {
  registerUrl: string;
}

export const TimelineTab: React.FC<TimelineTabProps> = ({ registerUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Analytics.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container>
      <TextBlock>
        <Text>
          <intl.FormattedMessage id="tabs.timeline.content.text" />
        </Text>
        <ButtonWrapper>
          <ButnLink href={registerUrl}>
            <TryButton color="secondary" variant="outline">
              <ButtonText>
                <intl.FormattedMessage id="tabs.timeline.contenttryBtn" />
              </ButtonText>
              <Svg.Arrow color={colors.palette.accent} />
            </TryButton>
          </ButnLink>
        </ButtonWrapper>
      </TextBlock>
      <ImageBlock>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Timeline"
          style={{ width: '100%' }}
        />
      </ImageBlock>
    </Container>
  );
};

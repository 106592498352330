// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled                          from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

const colors = helpers.theme.colors;

interface CardData {
  image: React.ReactElement;
  title: string;
  text: string;
}

const Container = styled(L.Flex)`
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  flex-direction: column;
  padding: 0 16px;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    margin-left: 40px;
    &:first-child {
      margin-left: 0;
    }
    width: 444px;
    height: auto;
    padding: 30px 17px 23px 39px;

    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(221, 225, 233, 0.8);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media screen and (min-width: 1440px) {
    width: 536px;
  }

  /* flex-wrap: wrap;
  width: 300px;
  height: auto;
  padding: 20px 12px 20px 20px;

  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(221, 225, 233, 0.8);

  align-items: flex-start;
  justify-content: space-between;

  @media screen and (min-width: 545px) {
    width: 536px;
    height: 294px;
    padding: 30px 24px 30px 35px;
  }

  @media screen and (min-width: 1111px) {
    margin-top: 40px;
    margin-right: 40px;

    :nth-child(1) {
      margin-top: 0;
    }
    :nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  } */
`;

const ImageWrapper = styled(L.Flex)`
  margin-bottom: 24px;
  @media screen and (min-width: 545px) {
    margin-bottom: 0;
  }
`;

const TextWrapper = styled(L.Flex)`
  flex-direction: column;
  margin-top: 22px;
  @media screen and (min-width: 1024px) {
    width: 236px;
    margin-top: 0;
  }
  @media screen and (min-width: 1440px) {
    width: 320px;
  }
`;

const Title = styled(L.Flex)`
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #3D4156;
`;

const Text = styled(L.Flex)`
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #888B9D;
  margin-top: 10px;
`;

export interface CardProps {
  data: CardData;
}

export const Card: React.FC<CardProps> = ({ data }) => (
  <Container>
    <ImageWrapper>
      {data.image}
    </ImageWrapper>
    <TextWrapper>
      <Title>
        <intl.FormattedMessage id={data.title} />
      </Title>
      <Text>
        <intl.FormattedMessage id={data.text} />
      </Text>
    </TextWrapper>
  </Container>
);

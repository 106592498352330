import * as React from 'react';
import styled from 'styled-components';
import * as intl from 'gatsby-plugin-intl';
import * as UI from 'components/@UI';
import * as L  from 'components/@UI/@Layout';

import { PhoneSvg } from './svg/svg';

const Container = styled(L.Flex)`
  width: 100%;
  background: #EFF1F5;
  overflow: hidden;
`;

const Content = styled(L.Flex)`
  max-width: 1112px;
  margin: 0 auto;
`;

const TextWrapper = styled(L.Flex)`
  flex-direction: column;
  position: relative;
  z-index: 1;
  flex: 1;
`;

const Title = styled(L.Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 0.04em;
  //text-transform: uppercase;
  color: #4442A8;
  padding: 32px 24px 0 16px;
  @media screen and (min-width: 1024px) {
    padding: 64px 0 0 48px;
    font-size: 40px;
    line-height: 54px;
  }
  @media screen and (min-width: 1440px) {
    padding: 64px 0 0 68px;
  }
`;

const Text = styled(L.Flex)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #3D4156;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  text-align: center;
  white-space: break-spaces;
  @media screen and (min-width: 1024px) {
    margin-top: 16px;
    padding: 0 0 0 48px;
    justify-content: flex-start;
    text-align: left;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 0 0 68px;
  }
`;

const ButtonWrapper = styled(L.Flex)`
  margin-top: 16px;
  padding: 0 16px;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    margin-top: 32px;
    padding: 0 0 0 48px;
    justify-content: flex-start;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 0 0 68px;
  }
`;

const ImageWrapper = styled(L.Flex)`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    flex: 1;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 68px 0 30px;
  }
`;

const ImageWrapper2 = styled(L.Flex)`
  width: 100%;
  padding: 0 10px;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const BottomText = styled(L.Flex)`
  margin-top: 16px;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #535875;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
  @media screen and (min-width: 1024px) {
    margin-top: 24px;
    margin-bottom: 0;
    justify-content: flex-start;
    text-align: left;
    padding: 0 0 0 48px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 0 0 68px;
  }
`;

const LoginText = styled(L.Flex)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #888B9D;
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 0 0 0 48px;
    margin: 16px 0 40px 0;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 0 0 68px;
  }
`;

const LoginButton = styled(UI.Button)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;

  padding: 0 23px;
  height: 52px;
  background: #E36A9D;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

const LinkContainer = styled(L.Box)`
  margin-left: 5px;
  display: inline-block;
`;

const RegisterLink = styled(UI.Link)`
  text-decoration: none;
`;

export type UniqueSellingPropositionProps = {
  loginUrl: string;
  registerUrl: string;
  onLoginClick(): void;
  onRegisterClick(): void;
};

export const UniqueSellingProposition: React.FC<UniqueSellingPropositionProps> = ({
  loginUrl,
  registerUrl,
  onLoginClick,
  onRegisterClick,
}) => {
  const handleRegisterClick = (e): void => {
    // e.preventDefault();
    onRegisterClick();
    // // @ts-ignore
    // if (typeof window.Calendly != "undefined") {
    //   // @ts-ignore
    //   window.Calendly.initPopupWidget({url: 'https://calendly.com/asoone'});
    // }
  };
  return (
    <Container>
      <Content>
        <TextWrapper>
          <Title>
            <intl.FormattedMessage id="uniqueSellingProposition.title" />
          </Title>
          <ImageWrapper2>
            {PhoneSvg}
          </ImageWrapper2>
          <Text>
            <intl.FormattedMessage id="uniqueSellingProposition.text" />
          </Text>

          <ButtonWrapper>
            <RegisterLink href={registerUrl} target="_blank" onClick={handleRegisterClick}>
              <LoginButton color="pink">
                <intl.FormattedMessage id="header.registrationBtn3" />
              </LoginButton>
            </RegisterLink>
          </ButtonWrapper>

          <BottomText>
            <intl.FormattedMessage id="uniqueSellingProposition.bottomText" />
          </BottomText>
          <LoginText>
            <intl.FormattedMessage id="uniqueSellingProposition.loginText" />
            <LinkContainer>
              <UI.Link href={loginUrl} onClick={onLoginClick}>
                <intl.FormattedMessage id="header.loginLink" />
              </UI.Link>
            </LinkContainer>
          </LoginText>
        </TextWrapper>
        <ImageWrapper>
          {PhoneSvg}
        </ImageWrapper>
      </Content>
    </Container>
  );
};

// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled                          from 'styled-components';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as G                                    from 'components/@UI/@Grid';
import * as Svg                                  from 'components/@UI/@Svg';

import { Tab } from './Tab';
import { MetaTab } from './@MetaTab';
import { Meta2Tab } from './@Meta2Tab';
import { TimelineTab } from './@TimelineTab';
import { SemanticTab } from './@SemanticTab';

const Container = styled(L.Flex)`
  width: 100%;
  max-width: 1112px;
  margin: 0 auto;
  flex-direction: column;
`;

const TabsContainer = styled(L.Flex)`
  justify-content: space-around;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #E3E6ED;
  }
`;

const TabWrapper = styled(L.Flex)``;

const Content = styled(L.Flex)`
  padding: 40px 16px 0 16px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    padding: 47px 47px 0 47px;
  }
  @media screen and (min-width: 1440px) {
    padding: 47px 0 0 24px;
  }
`;

const getTabTitle = (tabIndex: number): string => ['review', 'chat', 'timeline', 'semantic'][tabIndex];

const getContent = (tabIndex: number, registerUrl: string): React.ReactNode => {
  if (tabIndex === 0) {
    return (
      <SemanticTab registerUrl={registerUrl} />
    );
  }
  if (tabIndex === 1) {
    return (
      <MetaTab registerUrl={registerUrl} />
    );
  }
  if (tabIndex === 2) {
    return (
      <Meta2Tab registerUrl={registerUrl} />
    );
  }
  if (tabIndex === 3) {
    return (
      <TimelineTab registerUrl={registerUrl} />
    );
  }
};

export interface TabsProps {
  registerUrl: string;
  onTabClick(tabName: string): void;
}

export const Tabs: React.FC<TabsProps> = ({ registerUrl, onTabClick }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabClick = (tabIndex: number) => (): void => {
    setActiveTab(tabIndex);
    onTabClick(getTabTitle(tabIndex));
  };
  const [scale, setScale] = React.useState(0);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const listener = () => {
        setScale(window.innerWidth >= 1144 ? 1 : (window.innerWidth  / 1144));
      };
      window.addEventListener('resize', listener);
      window.addEventListener('load', listener);
      setScale(window.innerWidth >= 1144 ? 1 : (window.innerWidth  / 1144));
      
      return () => {
        window.removeEventListener('resize', listener);
        window.removeEventListener('load', listener);
      };
    }
    
  }, []);

  return (
    <Container>
      <TabsContainer>
        <TabWrapper>
          <Tab icon={Svg.Semantic} title="tabs.semantic.title" isActive={activeTab === 0} onClick={handleTabClick(0)} />
          <Tab icon={Svg.Star} title="tabs.meta.title" isActive={activeTab === 1} onClick={handleTabClick(1)} />
          <Tab icon={Svg.Bubble} title="tabs.meta2.title" isActive={activeTab === 2} onClick={handleTabClick(2)} />
          <Tab icon={Svg.Timeline} title="tabs.timeline.title" isActive={activeTab === 3} onClick={handleTabClick(3)} />
        </TabWrapper>
        </TabsContainer>
      <Content>
        {getContent(activeTab, registerUrl)}
      </Content>
    </Container>
  );
};

// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled     from 'styled-components';
import * as intl from 'gatsby-plugin-intl';


import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as Svg                                  from 'components/@UI/@Svg';

import FooterBg                                  from './assets/footer_bg.png';


const colors = helpers.theme.colors;

const Container = styled(L.Flex)`
  background: #4442A8;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 27px;
  background-image: url('${() => FooterBg}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Title = styled(UI.Bold32)`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #EFF1F5;
`;

const Text = styled(UI.Medium16)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #FFFFFF;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Button = styled(UI.Button )`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const RegisterLink = styled(UI.Link)`
  text-decoration: none;
`;

export type SmallUniqueSellingPropositionProps = {
  registerUrl: string;
  onRegisterClick(): void;
};

export const SmallUniqueSellingProposition: React.FC<SmallUniqueSellingPropositionProps> = ({
  registerUrl,
  onRegisterClick,
}) => (
  <Container>
    <Title>
      <intl.FormattedMessage id="smallUniqueSellingProposition.title" />
    </Title>
    <Text>
      <intl.FormattedMessage id="smallUniqueSellingProposition.text" />
    </Text>
    <RegisterLink href={registerUrl} onClick={onRegisterClick}>
      <Button color={'pink'} size={'lg'}>
        <intl.FormattedMessage id="header.registrationBtn2" />
      </Button>
    </RegisterLink>
  </Container>
);

import React from 'react';

export const PhoneSvg = (
  <svg width="100%" style={{ maxHeight: 512, maxWidth: 512 }} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
<path opacity="0.16" d="M433.694 373.692C398.701 350.82 384.191 340.193 323.688 345.229C246.757 351.631 186.679 369.697 105.674 398.66C75.6725 409.387 -6.3331 461.58 84.6725 496.566C148.697 521.18 205.409 474.932 295.128 496.566C349.605 509.703 478.201 508 504.699 455.588C526.986 411.504 487.042 408.561 433.694 373.692Z" fill="#BFC4D4"/>
<path opacity="0.24" d="M465.226 413.607C455.04 398.746 427.844 394.348 419.549 386.345C370.356 338.886 241.061 373.463 191.669 404.762C169.633 418.794 174.755 440.23 191.669 451.552C231.824 478.432 277.462 452.377 322.173 467.481C381.402 487.49 501.757 466.903 465.226 413.607Z" fill="#BFC4D4"/>
<path opacity="0.32" d="M437.217 426.592C444.422 442.954 399.818 457.311 387.793 453.329C369.057 447.125 306.934 401.704 330.107 387.21C342.378 379.536 430.013 410.23 437.217 426.592Z" fill="#BFC4D4"/>
<path opacity="0.24" d="M471.287 184.472C470.098 188.966 465.472 191.478 461.11 190.288C456.616 189.098 454.104 184.472 455.294 180.11C456.483 175.616 461.11 173.105 465.472 174.294C469.833 175.484 472.477 180.11 471.287 184.472Z" fill="#BFC4D4"/>
<path opacity="0.24" d="M165.575 129.654C175.861 118.385 175.064 100.911 163.796 90.6251C152.527 80.3388 135.053 81.1354 124.767 92.4042C114.48 103.673 115.277 121.147 126.546 131.433C137.814 141.719 155.288 140.923 165.575 129.654Z" fill="#BFC4D4"/>
<path opacity="0.24" d="M90.7914 128.137C90.2627 131.045 87.4869 133.028 84.4468 132.499C81.5389 131.97 79.5562 129.194 80.0849 126.154C80.6137 123.246 83.3894 121.264 86.4295 121.792C89.3374 122.321 91.3201 125.229 90.7914 128.137Z" fill="#BFC4D4"/>
<path d="M392.227 447.249L283.797 384.496C271.538 377.491 261.614 358.519 261.614 342.174V53.3663C261.614 45.0479 254.755 43.2967 258.987 40.232C259.133 40.0861 268.619 35.8539 268.911 35.708C273.289 33.9567 278.105 33.2271 283.943 36.5836L392.373 99.1903C404.632 106.195 414.556 125.167 414.556 141.512V430.32C414.556 438.93 411.783 445.206 407.259 447.978C406.967 448.124 395.584 453.67 395.292 453.67C391.06 455.129 397.627 450.313 392.227 447.249Z" fill="#332780"/>
<path opacity="0.6" d="M383.763 451.043L275.332 388.436C263.074 381.431 253.15 362.46 253.15 346.115V57.1607C253.15 40.8158 263.074 33.373 275.332 40.378L383.763 102.985C396.022 109.99 405.945 128.961 405.945 145.306V434.114C405.945 450.605 396.022 458.048 383.763 451.043Z" fill="#4442A8"/>
<path d="M381.866 452.065L273.435 389.458C261.176 382.453 251.253 363.481 251.253 347.136V58.3281C251.253 41.9832 261.176 34.5405 273.435 41.5454L381.866 104.152C394.125 111.157 404.048 130.129 404.048 146.474V435.282C404.048 451.627 394.125 459.069 381.866 452.065Z" fill="#332780"/>
<path d="M254.317 349.033V59.7875C254.317 45.4857 263.074 38.9186 273.727 45.0479L381.574 107.363C392.227 113.492 400.983 130.129 400.983 144.431V433.676C400.983 447.978 392.227 454.545 381.574 448.416L273.727 386.101C262.928 379.972 254.317 363.335 254.317 349.033Z" fill="#4442A8"/>
<path d="M409.01 215.502C407.988 215.502 407.697 214.334 407.697 213.313V193.757C407.697 191.568 409.01 188.795 410.761 187.92C411.053 187.774 411.491 187.628 411.783 187.628C412.804 187.628 413.096 188.795 413.096 189.817V209.372C413.096 211.561 411.783 214.334 410.031 215.21C409.594 215.502 409.302 215.502 409.01 215.502Z" fill="#4442A8"/>
<path d="M408.718 213.75V194.195C408.718 192.006 410.031 189.233 411.783 188.358C412.075 188.212 412.366 188.066 412.658 188.066C412.512 187.774 412.221 187.482 411.783 187.482C411.491 187.482 411.199 187.628 410.761 187.774C409.156 188.649 407.697 191.422 407.697 193.611V213.167C407.697 214.188 407.988 215.356 409.01 215.356H409.156C408.864 215.064 408.718 214.48 408.718 213.75Z" fill="#332780"/>
<circle r="11.8812" transform="matrix(0.866025 0.5 -2.20305e-08 1 332.626 387.805)" fill="#E3E6ED"/>
<path d="M319.121 361.192C314.815 355.835 313.339 348.625 315.825 345.088C318.312 341.551 323.818 343.026 328.124 348.383L325.552 352.043C329.858 357.4 331.333 364.61 328.847 368.146C326.361 371.683 320.855 370.208 316.549 364.851L319.121 361.192Z" fill="#E3E6ED"/>
<path d="M306.259 348.82L317.192 380.38L295.327 367.756L306.259 348.82Z" fill="#E3E6ED"/>
<path opacity="0.8" d="M325.8 295.068L285.375 370.225L254.32 361V335.493V306L325.8 295.068Z" fill="url(#paint0_linear)"/>
<path d="M353.965 121.842C342.582 115.421 326.821 116.588 309.601 126.512L252.686 159.347L281.143 175.838L338.058 143.003C354.841 133.371 370.019 132.057 381.402 137.749L353.965 121.842Z" fill="#E36A9D"/>
<path d="M222.915 176.568L251.372 193.059L281.143 175.838L252.54 159.348L222.915 176.568Z" fill="#EEEBF2"/>
<path d="M281.143 175.838L251.372 193.059L281.143 175.838Z" fill="url(#paint1_linear)"/>
<path d="M338.058 143.003L281.143 175.838L281.435 175.984V220.495L338.058 187.805C351.339 180.07 362.284 186.346 362.284 201.523C362.284 201.669 362.284 201.961 362.284 202.107L401.1 179.779C401.1 179.487 401.1 179.341 401.1 179.049C401.1 138.5 372.937 122.863 338.058 143.003Z" fill="#B43F85"/>
<path d="M281.143 175.838L251.372 193.059V204.734V237.715L281.289 220.495V175.984L281.143 175.838Z" fill="#9D9EB3"/>
<path d="M362.138 202.107C361.846 217.284 351.193 235.672 337.912 243.261L281.289 275.951V320.753L337.912 288.063C372.645 268.07 400.665 219.765 401.103 179.778L362.138 202.107Z" fill="#7878C9"/>
<path d="M281.289 275.95L251.372 293.317V337.973L281.289 320.753V275.95Z" fill="#9D9EB3"/>
<path d="M362.138 202.107C362.138 201.961 362.138 201.669 362.138 201.523C362.138 186.2 351.339 180.07 337.912 187.805L337.037 188.243L362.138 202.107Z" fill="#7C4096"/>
<path d="M362.138 202.107L337.183 188.243L333.243 190.578C331.053 204.588 321.276 220.057 309.455 226.77L252.832 259.46L281.289 275.951L337.912 243.261C351.193 235.672 361.846 217.43 362.138 202.107Z" fill="#ABAEEB"/>
<path d="M222.915 276.826L251.372 293.317L281.289 275.951L252.832 259.46L222.915 276.826Z" fill="#EEEBF2"/>
<path d="M251.372 337.974L222.769 321.191V276.68L251.372 293.171V337.974Z" fill="#D4D1D8"/>
<path d="M251.372 237.715L222.769 221.078V176.422L251.372 193.059V237.715Z" fill="#D4D1D8"/>
<path opacity="0.5" d="M381.109 137.552C381.255 137.552 381.401 137.698 381.401 137.698L353.965 121.791C342.582 115.369 326.821 116.537 309.6 126.461L296.32 134.195L325.945 150.102V194.759L338.058 187.754L337.182 188.192L333.242 190.527C331.053 204.537 321.275 220.006 309.454 226.719L296.5 234.162L325.799 250.5V295.017L337.912 288.012C372.645 268.019 400.665 219.714 401.102 179.727C401.102 179.436 401.102 179.29 401.102 178.998C401.394 157.983 393.514 143.535 381.109 137.552Z" fill="#4442A8"/>
<path d="M103.264 403.239C101.111 411.273 102.42 417.667 106.034 421.658C106.137 421.836 112.362 428.49 112.503 428.528C116.437 432.453 115.101 425.598 123.245 423.399C139.71 418.897 156.736 402.156 161.155 385.663C163.346 377.487 170.817 379.489 167.024 375.602C166.921 375.423 159.747 368.364 159.606 368.326C155.634 364.543 149.178 363.266 141.034 365.465C124.568 369.967 107.683 386.746 103.264 403.239Z" fill="#B43F85"/>
<path d="M110.362 410.579C105.981 426.931 115.765 436.654 132.334 432.33C148.799 427.829 165.825 411.088 170.244 394.595C174.664 378.102 164.842 368.521 148.273 372.844C131.704 377.167 114.781 394.087 110.362 410.579Z" fill="#E36A9D"/>
<path d="M150.242 392.559C152.198 392.026 153.24 393.212 152.749 395.044C152.258 396.877 150.385 398.792 148.429 399.326C146.473 399.859 145.431 398.673 145.922 396.841C146.554 395.046 148.427 393.131 150.242 392.559ZM136.551 396.294C138.507 395.76 139.549 396.946 139.058 398.778C138.567 400.611 136.694 402.526 134.738 403.06C132.782 403.593 131.74 402.408 132.231 400.575C132.722 398.743 134.736 396.865 136.551 396.294ZM136.768 419.166C131.22 420.701 126.973 418.505 126.377 413.964L129.811 411.862C130.248 415.304 133.443 416.915 137.674 415.782C142.047 414.688 146.714 410.802 149.267 406.349L152.059 406.644C148.63 412.675 142.457 417.668 136.768 419.166Z" fill="white"/>
<path d="M192.109 351.077L193.338 356.791L218.944 332.253L217.781 326.219L192.109 351.077Z" fill="#B43F85"/>
<path d="M192.25 351.039L193.338 356.791L158.619 338.294L157.276 332.157L192.25 351.039Z" fill="#B43F85"/>
<path d="M157.276 332.158L192.25 351.039L217.781 326.219L182.845 307.479L157.276 332.158Z" fill="#E36A9D"/>
<path d="M193.387 334.417C192.239 333.516 191.476 332.361 191.277 331.054C191.088 330.35 191.821 329.7 192.845 329.577C194.151 329.378 195.42 329.038 196.651 328.557C197.469 328.791 198.672 328.771 199.123 328.197C199.714 327.585 199.704 326.983 199.45 326.598C201.675 324.189 202.302 321.453 198.088 319.258C193.836 316.922 190.566 318.252 188.021 320.595C187.382 320.465 186.536 320.691 185.944 321.303C185.353 321.915 185.721 322.723 186.295 323.173C185.957 324.17 185.94 325.232 186.166 326.078C186.355 326.783 185.661 327.574 184.598 327.556C182.972 327.69 181.27 327.541 179.634 327.073C177.5 326.436 174.924 326.975 173.497 328.415L170.99 330.9C170.502 331.333 170.691 332.038 171.227 332.348L189.015 341.783C189.692 342.055 190.576 341.969 191.064 341.536L193.571 339.052C194.998 337.612 194.827 335.845 193.387 334.417Z" fill="white"/>
<path d="M143.007 294.807L141.496 300.446L198.199 286.178L199.823 280.116L143.007 294.807Z" fill="#B43F85"/>
<path d="M143.148 294.845L141.637 300.483L109.817 268.69L111.403 262.77L143.148 294.845Z" fill="#B43F85"/>
<path d="M111.403 262.77L143.148 294.845L199.823 280.116L168.04 248.182L111.403 262.77Z" fill="#E36A9D"/>
<path d="M173.889 262.44C172.706 261.217 170.451 260.612 168.815 261.081L157.258 264.178L152.976 259.858C151.202 258.023 147.818 257.117 145.364 257.819C144.546 258.053 144.395 258.617 144.986 259.228L148.676 262.937C149.756 263.981 150.196 265.157 150.073 266.181L148.91 272.215L158.205 281.505C159.285 282.549 161.258 283.078 162.716 282.713L168.903 281.048L176.727 278.914L180.959 277.781C182.595 277.313 182.897 276.185 181.855 275C181.405 274.426 180.597 274.058 179.789 273.69C181.284 273.185 181.624 271.916 180.403 270.833C179.568 270.005 178.337 269.524 177.03 269.325C178.666 268.857 179.006 267.589 177.964 266.403C177.026 265.396 175.372 264.802 173.849 264.847C174.846 264.51 174.828 263.447 173.889 262.44Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M149.804 286.355L155.813 284.792C157.13 284.39 157.535 283.441 156.7 282.612L145.977 271.883C145.141 271.055 143.45 270.601 142.133 271.004L136.124 272.567C134.808 272.969 134.402 273.918 135.238 274.747L145.961 285.476C146.796 286.304 148.488 286.758 149.804 286.355ZM143.624 276.082C144.751 276.384 145.851 276.226 146.04 275.521C146.191 274.957 145.356 274.129 144.266 273.686C143.138 273.384 142.038 273.542 141.849 274.247C141.66 274.952 142.496 275.78 143.624 276.082Z" fill="white"/>
<path d="M86.1927 264.119L21.3063 301.581L23.2031 304.866L22.9067 304.353C22.966 304.456 23.3216 305.072 23.3809 305.174C29.427 315.647 48.7639 315.708 66.7308 305.334C84.5951 295.02 94.3135 278.184 88.2674 267.712C88.2081 267.61 87.8524 266.994 87.7931 266.891L86.1927 264.119Z" fill="#7878C9"/>
<path d="M63.1743 299.174C81.0922 288.829 91.4244 273.181 86.252 264.222C81.0795 255.263 62.3611 256.386 44.4432 266.731C26.5253 277.076 16.1931 292.725 21.3656 301.684C26.538 310.643 45.2564 309.519 63.1743 299.174Z" fill="#ABAEEB"/>
<rect width="21.6684" height="3.6114" transform="matrix(1 0 -0.5 0.866025 37.5555 292.591)" fill="white"/>
<path d="M49.2926 291.027L65.5439 281.644H43.8755L49.2926 291.027Z" fill="white"/>
<rect width="7.2228" height="14.4456" transform="matrix(1 0 -0.5 0.866025 58.321 269.134)" fill="white"/>
<path d="M43.5882 217.492L47.5332 211.291C47.5332 211.291 40.3684 206.66 39.4731 206.194C36.9434 204.994 34.2221 204.672 31.4652 205.428C27.1944 206.725 24.213 210.338 23.2549 214.73L20.9578 213.377C20.257 212.934 18.6608 212.024 18.6608 212.024C16.2872 211.023 13.7603 210.725 11.1977 211.503C4.79143 213.45 1.19959 220.774 3.00938 228.016C4.18943 232.661 9.76039 237.693 13.268 240.401C16.7755 243.109 24.1862 247.918 26.8712 249.225C29.5561 250.532 29.2265 248.594 29.2265 248.594C29.2265 248.594 42.9286 230.57 43.5882 217.492Z" fill="#B43F85"/>
<path d="M37.9292 209.615C33.464 210.89 30.3676 215.029 29.5655 219.62C26.5622 216.021 21.9326 214.393 17.4673 215.668C11.061 217.614 7.46924 224.939 9.27902 232.181C12.8164 245.213 38.8444 254.776 38.8444 254.776C38.8444 254.776 56.3793 231.979 52.8419 218.946C50.9938 211.88 44.3355 207.669 37.9292 209.615Z" fill="#E36A9D"/>
<path d="M143.671 343.426V335.313L137.383 337.747L104.623 318.396C100.972 316.164 94.8869 316.164 91.2357 318.396L57.7668 337.666L53.1014 334.217V342.534C52.8986 343.954 53.9128 345.577 55.9412 346.591L88.7009 365.739L92.5549 367.97C96.2061 370.202 102.291 370.202 105.942 367.97L141.44 347.483C142.86 346.469 143.874 345.049 143.671 343.426Z" fill="#7878C9"/>
<path d="M140.831 331.053L104.623 309.673C100.972 307.442 94.8869 307.442 91.2358 309.673L55.7384 330.161C52.0872 332.392 52.0872 335.84 55.7384 337.869L88.4981 357.017L92.3521 359.248C96.0032 361.479 102.089 361.479 105.74 359.248L141.237 338.761C144.685 336.732 144.685 333.284 140.831 331.053Z" fill="#ABAEEB"/>
<path d="M79.5408 335.412L79.5439 335.415L74.961 336.124C73.2882 336.383 71.5686 335.81 71.1203 334.844C70.6721 333.878 71.6649 332.885 73.3377 332.627L76.7138 332.104L77.0378 332.054C77.5541 332.001 78.602 331.999 79.5488 332.66C79.5488 332.66 81.742 334.234 80.6413 335.075C80.6413 335.075 80.2448 335.332 79.5408 335.412ZM110.754 344.864L105.523 341.844C105.191 341.624 105.162 341.461 105.175 341.383L105.178 341.377L109.277 332.544L109.265 332.543L109.411 332.071C109.702 331.267 106.676 332.006 106.676 332.006C103.233 332.788 101.698 335.05 101.289 335.766L95.5955 348.034C95.1472 349 96.14 349.993 97.8129 350.251C99.4857 350.51 101.205 349.937 101.654 348.971L103.077 345.904C103.121 345.835 103.277 345.669 103.677 345.899L106.319 347.424C107.544 348.131 109.529 348.131 110.754 347.424C111.979 346.717 111.979 345.571 110.754 344.864ZM97.5793 340.738C97.5793 340.738 96.4383 341.719 95.3579 341.096L94.2775 340.472L94.2775 340.472L76.0204 329.931C74.7957 329.224 74.7957 328.078 76.0204 327.371C77.245 326.664 79.2306 326.664 80.4552 327.371L85.4266 330.241C86.2758 330.678 86.9294 330.52 86.9294 330.52L86.9315 330.524L104.661 327.781L104.659 327.78C104.995 327.723 105.102 327.547 105.117 327.519L106.686 324.138C107.134 323.172 108.853 322.599 110.526 322.858C112.199 323.117 113.192 324.109 112.744 325.075L112.016 326.643L114.727 326.224C116.4 325.965 118.119 326.538 118.567 327.504C119.016 328.47 118.023 329.463 116.35 329.721L91.6791 333.538C91.5444 333.57 91.3829 333.649 91.6503 333.834L97.0481 336.951L96.9966 336.982C96.9966 336.982 100.068 338.811 97.5793 340.738Z" fill="white"/>
<path d="M150 213.227V208.703L151.313 208.411C153.211 204.325 157.589 200.238 163.864 196.59C178.02 188.417 197.283 186.228 206.769 191.628C208.375 192.504 209.542 193.671 210.418 194.839L212.023 194.401V199.363C212.169 204.762 207.353 211.184 198.305 216.437C196.846 217.313 195.386 218.043 193.781 218.772C194.511 225.193 183.565 233.074 183.565 233.074C183.565 233.074 182.106 228.404 176.706 223.734C168.388 224.902 160.507 224.172 155.4 221.253C152.043 219.21 150.292 216.583 150.146 213.519V213.227H150Z" fill="#7878C9"/>
<path d="M155.254 216.729C145.768 211.184 149.562 200.093 163.718 191.92C177.874 183.748 197.137 181.559 206.623 186.958C216.109 192.504 212.315 203.595 198.159 211.767C196.7 212.643 195.24 213.373 193.635 214.102C194.365 220.524 183.419 228.404 183.419 228.404C183.419 228.404 181.96 223.734 176.56 219.064C168.096 220.378 160.216 219.648 155.254 216.729Z" fill="#ABAEEB"/>
<path d="M135.969 229.06L137.215 224.409L128.511 224.191L120.045 220.261C119.096 219.856 118.364 219.206 118.271 218.426L115.923 208.581C115.455 206.945 112.288 205.794 110.436 206.506L99.4498 211.419C98.7727 211.691 97.7104 211.708 96.5827 211.406L80.9735 207.072C80.9735 207.072 80.9735 207.072 80.8325 207.035L78.5623 203.103L77.2781 207.895C77.1648 208.318 77.1924 208.779 77.502 209.315L84.8793 218.997C85.3298 219.571 85.4229 220.351 84.8489 220.802L80.7454 224.839L78.6033 223.812L77.3946 228.323C77.3569 228.464 77.3191 228.605 77.2813 228.746L77.2436 228.887C77.2611 229.949 78.867 231.286 80.9538 231.392L96.5104 232.538C97.5349 232.662 98.6626 232.964 99.3951 233.614L110.506 242.332C112.393 243.744 115.58 243.692 115.958 242.282L118.093 233.185C118.244 232.621 118.818 232.17 119.778 231.974L133.862 230.158C135.347 230.254 135.959 229.662 135.969 229.06Z" fill="#7878C9"/>
<path d="M121.188 215.431L135.413 222.113C137.837 223.216 137.731 225.302 135.39 225.582L121.306 227.398C120.384 227.453 119.669 227.866 119.621 228.609L117.486 237.706C117.07 239.256 113.884 239.309 112.033 237.756L100.923 229.037C100.191 228.388 99.0628 228.086 98.0383 227.962L82.4817 226.816C79.8311 226.559 77.9156 224.686 79.1391 223.503L86.3391 216.367C86.7721 215.878 86.7822 215.277 86.3695 214.562L78.9922 204.88C77.7815 203.196 79.7854 201.92 82.3227 202.599L97.9319 206.933C98.9187 207.197 99.9809 207.18 100.799 206.946L111.785 202.033C113.675 201.18 116.842 202.33 117.272 204.108L119.62 213.953C119.648 214.414 120.239 215.025 121.188 215.431Z" fill="#ABAEEB"/>
</g>
<defs>
<linearGradient id="paint0_linear" x1="305.595" y1="342.12" x2="276.88" y2="280.311" gradientUnits="userSpaceOnUse">
<stop stopColor="#441B9A" stopOpacity="0"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="132.757" y1="184.453" x2="281.345" y2="184.453" gradientUnits="userSpaceOnUse">
<stop offset="0.0353535" stopColor="#9D9EB3"/>
<stop offset="0.5808" stopColor="#D4D1D8"/>
<stop offset="1" stopColor="#EEEBF2"/>
</linearGradient>
<clipPath id="clip0">
<rect width="512" height="512" fill="white"/>
</clipPath>
</defs>
</svg>
);
